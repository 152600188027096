import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Line from '../../images/line.jpg';
import AppStore from '../../images/wrtsapp.jpg';
import GooglePlay from '../../images/wrtsgoogle.jpg';
import { useAppContent } from '../../hooks/getAppContent';

const DownloadApp = () => {
    const data = useAppContent();
    const appData = data.wordpressPage.acf;
    return (
        <section className="page-section smallwdt" id="abouthome">
            <div className="container">
                <div className="homeglobelft alignmidd">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: appData.app_title,
                        }}
                    />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: appData.app_content,
                        }}
                    />

                    <Link
                        className="wrtsbtn yellowbtn"
                        to={appData.app_learn_more_link}
                    >
                        LEARN MORE
                    </Link>
                    <Link
                        className="wrtsbtn bluebtn"
                        to={appData.app_non_profit_link}
                    >
                        NON PROFIT
                    </Link>
                    <img className="line" src={Line} alt="line" />
                    <div className="abouthomeapp">
                      <div className='text-app_txt'>
                            <h6
                                className="bluetxt"
                                dangerouslySetInnerHTML={{
                                    __html: appData.app_download_text,
                                }}
                            />
                        </div> 
                        <div className='app-icons_links'>

                            <a
                                href="https://apps.apple.com/us/app/we-rock-the-spectrum-2-0/id1555275663"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={AppStore} alt="AppStore Icon" />
                            </a>
                            {` `}
                            <a
                                href="https://play.google.com/store/apps/details?id=com.werockthespectrum"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={GooglePlay} alt="GooglePlay icon" />
                            </a>
                            <img src="https://werockthespectrumoakville.wrtsfranchise.com/wp-content/uploads/2023/09/WRTS_Download_Mobile_App_QR_Code.png" alt="WRTS_Download_Mobile_App_QR_Code icon" />
                        </div>
                      
                    </div>
                </div>
                <div className="homeglobergt alignmidd">
                    <Img
                        fluid={
                            appData.app_right_side_image.localFile
                                .childImageSharp.fluid
                        }
                        className="globe"
                        alt="globe"
                    />
                </div>
            </div>
        </section>
    );
};
export default DownloadApp;
